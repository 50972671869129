<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Danh sách tài liệu chuyên mục: {{ category_material_title }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogMaterial"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm tài liệu</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp các tài liệu</span>
      </v-btn>

      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên tài liệu</th>
                    <th scope="col">Là tài liệu premium</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(material, i) in materials">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ material.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ material.is_premium == 1 ? "Premium" : "Thường" }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editMaterial(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteMaterial(material.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!materials.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="1000px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled cursor-pointer"
                      v-for="(item, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1">
                          <span style="color: #3699ff">{{ item.name }}</span>
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortMaterial"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <FormMaterial
      :show_dialog="dialogMaterial"
      :skills="skill_tags"
      :type_form="type_form"
      :list_file_type="list_file_type"
      :offset="offset_input"
      :material="material"
      @setDialog="(e) => (this.dialogMaterial = e)"
      @resetData="getAllMaterial()"
    />
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import draggable from "vuedraggable";
import "vue-loading-overlay/dist/vue-loading.css";
import FormMaterial from "@/view/pages/material/FormMaterial";
import Swal from "sweetalert2";
export default {
  name: "MaterialList",
  components: {
    draggable,
    FormMaterial,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      category_material_title: "",
      materials: [],
      material: {},
      skill_tags: [],
      list_file_type: [],
      dialogMaterial: false,
      type_form: null,
      offset_input: 0,
      dialogSort: false,
      data_draggable: [],
    };
  },
  computed: {
    category_material_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllSkill();
    this.getAllMaterial();
    this.listFileType();
  },
  methods: {
    async listFileType() {
      let vm = this;
      await ApiService.get("prep-app/category-material/list-file-type").then(
        function (response) {
          if (response.status === 200) {
            vm.list_file_type = response.data;
          }
        }
      );
    },
    async getAllSkill() {
      let vm = this;
      await ApiService.get("prep-app/tag/list-skill").then(function (response) {
        if (response.status === 200) {
          vm.skill_tags = response.data;
        }
      });
    },
    async getAllMaterial() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/category-material/" +
            this.category_material_id +
            "/material"
        );
        console.log(res);
        if (res.status === 200) {
          vm.category_material_title = res.data.category_material;
          vm.materials = res.data.materials;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogMaterial() {
      this.dialogMaterial = true;
      this.offset_input = this.materials.length + 1;
      this.type_form = "add";
    },
    editMaterial(index) {
      this.dialogMaterial = true;
      this.material = this.materials[index];
      this.type_form = "update";
    },
    deleteMaterial(material_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/category-material/" +
              this.category_material_id +
              "/material/" +
              material_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllMaterial();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.materials));
      this.dialogSort = true;
    },
    sortMaterial() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              material_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/category-material/" +
              this.category_material_id +
              "/material/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllMaterial();
                vm.$toasted.success("Sắp xếp thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
