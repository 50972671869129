<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogMaterial"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm tài liệu</span
              >
              <span class="headline" v-else>Sửa tài liệu</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogMaterial = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tên tài liệu:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Là tài liệu premium:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-switch
                        class="mt-0 pt-0 ml-2"
                        v-model="is_premium_input"
                        :label="is_premium_input ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Kỹ năng:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        :items="skills"
                        v-model="skill_input"
                        item-text="name"
                        item-value="id"
                        color="blue-grey lighten-2"
                        solo
                        chips
                        dense
                        multiple
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Loại tài liệu:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-radio-group
                        class="mt-0 pt-0"
                        v-model="file_type_input"
                        row
                      >
                        <template v-for="(type, key) in list_file_type">
                          <v-radio
                            :label="type.label"
                            :value="type.value"
                            :key="key"
                          ></v-radio>
                        </template>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4" v-if="file_type_input">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tài liệu:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <div class="d-flex">
                        <b-form-file
                          @change="onChange"
                          placeholder="Chọn tệp tin"
                          :accept="checkInputAcceptAttribute()"
                          class="mb-2"
                          id="fileMaterial"
                        ></b-form-file>
                        <v-btn
                          outlined
                          color="blue"
                          class="ml-2"
                          @click="setDefaultMaterialFile()"
                          >Xóa file</v-btn
                        >
                      </div>

                      <vuetify-audio
                        v-if="
                          transformTypeToText(file_type_input) === 'Audio' &&
                          audio_data != null
                        "
                        :file="audio_data"
                        color="success"
                      ></vuetify-audio>
                      <b-img
                        v-if="
                          transformTypeToText(file_type_input) === 'Image' &&
                          image_data != null
                        "
                        fluid
                        thumbnail
                        :rounded="true"
                        :src="image_data"
                      ></b-img>
                      <b-embed
                        v-if="
                          transformTypeToText(file_type_input) === 'PDF' &&
                          pdf_data != null
                        "
                        type="iframe"
                        aspect="16by9"
                        :src="pdf_data"
                      ></b-embed>
                      <Media
                        v-if="
                          transformTypeToText(file_type_input) === 'Video' &&
                          video_data != null
                        "
                        style="width: 70%"
                        :kind="'video'"
                        :controls="true"
                        :src="video_data"
                      >
                      </Media>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditMaterial(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditMaterial(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogMaterial = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Media from "@dongido/vue-viaudio";
export default {
  name: "FormMaterial",
  components: {
    Media,
    VuetifyAudio: () => import("vuetify-audio"),
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    offset: {
      type: Number,
      default: 0,
    },
    material: {},
    skills: {
      type: Array,
      default: () => [],
    },
    list_file_type: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      name_input: null,
      is_premium_input: false,
      material_id: null,
      skill_input: [],
      file_type_input: null,
      audio_file: null,
      audio_data: null,

      pdf_file: null,
      pdf_data: null,

      image_file: null,
      image_data: null,

      video_file: null,
      video_data: null,
    };
  },
  computed: {
    dialogMaterial: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
    category_material_id: function () {
      return this.$route.params.id;
    },
  },
  watch: {
    dialogMaterial(val) {
      if (val) {
        this.setDataForm();
      }
      if (!val) {
        this.setDefaultMaterialFile();
        this.setDefaultLabelInput();
      }
    },
    file_type_input() {
      this.setDefaultLabelInput();
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.is_premium_input = false;
        this.skill_input = [];
        this.file_type_input = null;
        this.setDefaultMaterialFile();
      } else {
        this.material_id = this.material.id;
        this.name_input = this.material.name;
        this.skill_input = this.material.skills.map((e) => e.id);
        this.is_premium_input = this.material.is_premium === 1;
        this.file_type_input = this.material.file_type
          ? parseInt(this.material.file_type)
          : null;
        this.setDefaultMaterialFile();
        if (this.material.path) {
          let type = this.transformTypeToText(this.file_type_input);
          if (type === "Image") {
            this.image_data = this.material.path;
          } else if (type === "Audio") {
            this.audio_data = this.material.path;
          } else if (type === "PDF") {
            this.pdf_data = this.material.path;
          } else if (type === "Video") {
            this.video_data = this.material.path;
          }
        }
      }
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        // this.setDefaultMaterialFileByType(this.file_type_input);
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.setFileMaterial(this.file_type_input, file, e.target.result);
      };
      reader.readAsDataURL(file);
    },
    setDefaultMaterialFileByType(file_type) {
      let type = this.transformTypeToText(file_type);
      if (type === "Image") {
        this.image_file = null;
        this.image_data = null;
      } else if (type === "Audio") {
        this.audio_file = null;
        this.audio_data = null;
      } else if (type === "PDF") {
        this.pdf_file = null;
        this.pdf_data = null;
      } else if (type === "Video") {
        this.video_file = null;
        this.video_data = null;
      }
    },
    setDefaultMaterialFile() {
      this.image_file = null;
      this.image_data = null;
      this.audio_file = null;
      this.audio_data = null;
      this.pdf_file = null;
      this.pdf_data = null;
      this.video_file = null;
      this.video_data = null;
    },
    setFileMaterial(file_type, file, data) {
      let type = this.transformTypeToText(file_type, file, data);
      if (type === "Image") {
        this.image_file = file;
        this.image_data = data;
      } else if (type === "Audio") {
        this.audio_file = file;
        this.audio_data = data;
      } else if (type === "PDF") {
        this.pdf_file = file;
        this.pdf_data = data;
      } else if (type === "Video") {
        this.video_file = file;
        this.video_data = data;
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên tài liệu !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.file_type_input === "" || this.file_type_input == null) {
        this.$toasted.error("Chưa chọn loại tài liệu !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.file_type_input) {
        // kiểm tra đã nhập file hay chưa ( theo type)
        if (
          this.transformTypeToText(this.file_type_input) === "Audio" &&
          this.audio_data == null
        ) {
          this.$toasted.error("Chưa nhập file audio !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (
          this.transformTypeToText(this.file_type_input) === "Image" &&
          this.image_data == null
        ) {
          this.$toasted.error("Chưa nhập file image !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (
          this.transformTypeToText(this.file_type_input) === "PDF" &&
          this.pdf_data == null
        ) {
          this.$toasted.error("Chưa nhập file pdf !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (
          this.transformTypeToText(this.file_type_input) === "Video" &&
          this.video_data == null
        ) {
          this.$toasted.error("Chưa nhập file video !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        // kiểm tra định dạng file và kiểm tra size file
        if (
          this.transformTypeToText(this.file_type_input) === "Audio" &&
          this.audio_file != null
        ) {
          if (this.getExtensionFile(this.audio_file) !== "mp3") {
            this.$toasted.error("Audio phải có định dạng mp3 !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
          if (this.getFileSize(this.audio_file) > 550000000) {
            this.$toasted.error("Kích cỡ file phải nhỏ hơn 500MB !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
        }
        if (
          this.transformTypeToText(this.file_type_input) === "Image" &&
          this.image_file != null
        ) {
          if (
            !(
              this.getExtensionFile(this.image_file) === "jpeg" ||
              this.getExtensionFile(this.image_file) === "jpg"
            )
          ) {
            this.$toasted.error("Không đúng định dạng ảnh !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
          if (this.getFileSize(this.image_file) > 550000000) {
            this.$toasted.error("Kích cỡ file phải nhỏ hơn 500MB !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
        }
        if (
          this.transformTypeToText(this.file_type_input) === "PDF" &&
          this.pdf_file != null
        ) {
          if (this.getExtensionFile(this.pdf_file) !== "pdf") {
            this.$toasted.error("Không đúng định dạng pdf !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
          if (this.getFileSize(this.pdf_file) > 550000000) {
            this.$toasted.error("Kích cỡ file phải nhỏ hơn 500MB !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
        }
        if (
          this.transformTypeToText(this.file_type_input) === "Video" &&
          this.video_file != null
        ) {
          if (this.getExtensionFile(this.video_file) !== "mp4") {
            this.$toasted.error("Không đúng định dạng mp4 !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
          if (this.getFileSize(this.video_file) > 550000000) {
            this.$toasted.error("Kích cỡ file phải nhỏ hơn 500MB !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
        }
      }

      return flat;
    },
    btnAddOrEditMaterial(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("is_premium", this.is_premium_input ? 1 : 0);
      formData.append("skill_ids", this.skill_input);
      if (this.file_type_input != null)
        formData.append("file_type", this.file_type_input);
      if (this.file_type_input != null)
        formData.append(
          "file",
          this.checkSaveFileMaterial(this.file_type_input)
        );
      this.is_call_api = true;
      if (type === 1) {
        formData.append("offset", this.offset);
        ApiService.post(
          "prep-app/category-material/" +
            this.category_material_id +
            "/material",
          formData
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogMaterial = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post(
          "prep-app/category-material/" +
            this.category_material_id +
            "/material/" +
            vm.material_id,
          formData
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogMaterial = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    checkSaveFileMaterial(file_type) {
      let type = this.transformTypeToText(file_type);
      let file = null;
      if (type === "Image") {
        file = this.image_file;
      } else if (type === "Audio") {
        file = this.audio_file;
      } else if (type === "PDF") {
        file = this.pdf_file;
      } else if (type === "Video") {
        file = this.video_file;
      }
      return file;
    },
    checkInputAcceptAttribute() {
      let accept = "";
      let type = this.transformTypeToText(this.file_type_input);
      if (type === "Image") {
        accept = "image/*";
      } else if (type === "Audio") {
        accept = "audio/*";
      } else if (type === "PDF") {
        accept = "application/pdf";
      } else if (type === "Video") {
        accept = "video/mp4,video/x-m4v,video/*";
      }
      return accept;
    },
    transformTypeToText(file_type) {
      return this.list_file_type.filter((e) => e.value === file_type)[0].label;
    },
    getExtensionFile(file) {
      let filename = file.name;
      return filename.split(".").pop();
    },
    setDefaultLabelInput() {
      if (document.getElementById("fileMaterial")) {
        document.getElementById(
          "fileMaterial"
        ).nextSibling.children[0].innerHTML = "Chọn tệp tin";
      }
    },
    getFileSize(file) {
      return file.size;
    },
  },
};
</script>

<style scoped></style>
